// third party
import { configureStore } from '@reduxjs/toolkit';

// project import
import customizationReducer from '../redux/customizationReducer';
import userDetails from '../modules/authSlice';
import serviceInfo from '../modules/services';
import customerInfo from '../modules/customers';
import dashboardInfo from '../modules/dashboard';

// ==============================|| REDUCER ||============================== //

export const store = configureStore({
  reducer: {
    customization: customizationReducer,
    userDetails: userDetails,
    dashboardInfo: dashboardInfo,
    serviceInfo: serviceInfo,
    customerInfo: customerInfo
  }
});


