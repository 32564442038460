import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../services/api';

// create actions
export const getDashboardData = createAsyncThunk("getDashboardData", async (data, { rejectWithValue }) => {
    try {
        const response = await api.getDashboard(data).catch(error => {
            throw error;
        });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});


const dashboardInfo = createSlice({
    name: "dashboardInfo",
    initialState: {
        totalData: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.loading = false;
                state.totalData.push(action.payload.data[0]);
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    }
});

export default dashboardInfo.reducer;

