import API from "./axios";

//Auth API
export const signIn = (data) => API.post('/uv-salon/service-api/v1/admin/login', data);
export const changePassword = (data) => API.post('/uv-salon/service-api/v1/admin/change-password', data);

//Dashboard
export const getDashboard = (data) => API.post('/uv-salon/service-api/v1/admin/dashboard', data);

//Customers
export const addCustomer = (data) => API.post('/uv-salon/service-api/v1/admin/add-customer', data);
export const updateCustomer = (data) => API.post('/uv-salon/service-api/v1/admin/edit-customer', data);
export const deleteCustomer = (data) => API.post('/uv-salon/service-api/v1/admin/delete-customer', data);
export const listCustomers = (data) => API.post('/uv-salon/service-api/v1/admin/list-all-customer', data);
export const getCustomer = (data) => API.post('/uv-salon/service-api/v1/admin/get-customer', data);

//Services
export const addService = (data) => API.post('/uv-salon/service-api/v1/admin/add-service', data);
export const updateService = (data) => API.post('/uv-salon/service-api/v1/admin/edit-service', data);
export const deleteService = (data) => API.post('/uv-salon/service-api/v1/admin/delete-service', data);
export const listServices = (data) => API.post('/uv-salon/service-api/v1/admin/list-all-services', data);

//Customer Services
export const addCustomerServices = (data) => API.post('uv-salon/service-api/v1/admin/add-customer-service', data);
export const listCustomerServices = (data) => API.post('/uv-salon/service-api/v1/admin/list-customer-services', data);

export const getServices = (data) => API.post('/uv-salon/service-api/v1/admin/list-service', data);