import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../services/api';

// create actions
export const signInUser = createAsyncThunk("signInUser", async (data, { rejectWithValue }) => {
    try {
        const response = await api.signIn(data).catch(error => {
            throw error;
        });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});


const userDetails = createSlice({
    name: "userDetails",
    initialState: {
        users: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(signInUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInUser.fulfilled, (state, action) => {
                state.loading = false;
                state.users.push(action.payload.data);
            })
            .addCase(signInUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    }
});

export default userDetails.reducer;

