import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../services/api';

// create actions
export const addCustomer = createAsyncThunk("addCustomer", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.addCustomer(data).catch(error => {
            throw error;
        });

        await dispatch(customerDetails({}));

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

// update actions
export const updateCustomer = createAsyncThunk("updateCustomer", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.updateCustomer(data).catch(error => {
            throw error;
        });

        await dispatch(customerDetails({}));

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});


// delete actions
export const deleteCustomer = createAsyncThunk("deleteCustomer", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.deleteCustomer(data).catch(error => {
            throw error;
        });

        await dispatch(customerDetails({}));

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const customerDetails = createAsyncThunk("customerDetails", async (data, { rejectWithValue }) => {
    try {
        const response = await api.listCustomers(data).catch(error => {
            throw error;
        });
      
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getSingleCustomer = createAsyncThunk("getSingleCustomer", async (data, { rejectWithValue }) => {
    try {
        const response = await api.getCustomer(data).catch(error => {
            throw error;
        });
      
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const addCustomerService = createAsyncThunk("addCustomerServices", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.addCustomerServices((data)).catch(error => {
            throw error;
        });

        await dispatch(customerServiceDetails({ customer_id: data.customer_id }));
        await dispatch(getSingleCustomer({ customer_id: data.customer_id }));
   
        return response.data;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error);
    }
});

export const customerServiceDetails = createAsyncThunk("customerServiceDetails", async (data, { rejectWithValue }) => {
    try {
        const response = await api.listCustomerServices(data).catch(error => {
            throw error;
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});



const customerInfo = createSlice({
    name: "customerInfo",
    initialState: {
        customers: [],
        customersDetails: [],
        singleCustomer: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(customerDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(customerDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.customers = action.payload.data;
            })
            .addCase(customerDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(customerServiceDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(customerServiceDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.customersDetails = action.payload.data;
            })
            .addCase(customerServiceDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getSingleCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.singleCustomer = action.payload.data;
            })
            .addCase(getSingleCustomer.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    }
});

export default customerInfo.reducer;

