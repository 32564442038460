import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));

const Customers = Loadable(lazy(() => import('../views/Customers')));
const Services = Loadable(lazy(() => import('../views/Services')));
const CustomerDetails = Loadable(lazy(() => import('../views/CustomerDtails')));
const ChangePassword = Loadable(lazy(() => import('../views/Profile')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    { path: '/customer-list', element: <Customers /> },
    { path: '/services', element: <Services /> },
    { path: '/customer-details/:id', element: <CustomerDetails /> },
    { path: '/profile/change-password', element: <ChangePassword /> }
  ]
};

export default MainRoutes;
