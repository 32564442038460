import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../services/api';

// create actions
export const addService = createAsyncThunk("addService", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.addService(data).catch(error => {
            throw error;
        });
        
        await dispatch(serviceDetails({}));
        
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

// update actions
export const updateService = createAsyncThunk("updateService", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.updateService(data).catch(error => {
            throw error;
        });
        
        await dispatch(serviceDetails({}));
        
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

// delete actions
export const deleteService = createAsyncThunk("deleteService", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await api.deleteService(data).catch(error => {
            throw error;
        });
        
        await dispatch(serviceDetails({}));
        
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const serviceDetails = createAsyncThunk("serviceDetails", async (data, { rejectWithValue }) => {
    try {
        const response = await api.listServices(data).catch(error => {
            throw error;
        });
       
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});



export const getServiceDetails = createAsyncThunk("getServiceDetails", async (data, { rejectWithValue }) => {
    try {
        const response = await api.getServices(data).catch(error => {
            throw error;
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});


const serviceInfo = createSlice({
    name: "serviceInfo",
    initialState: {
        services: [],
        serviceArray: [],
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(serviceDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(serviceDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.serviceArray = action.payload.data;
            })
            .addCase(serviceDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getServiceDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getServiceDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.services = action.payload.data;
            })
            .addCase(getServiceDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            });
    }
});

export default serviceInfo.reducer;